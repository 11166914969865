.__CollectionsMenu__ {
  width: 100%;
  padding-bottom: 0.3em;

  @media (max-width: @screen-sm-max) {
    display: none;
  }
  .button-no-background {
    padding: 0;
    background-color: @layout-header-background;
    border-style: none;
    border-width: 0;
    & > span {
      vertical-align: top;
      line-height: 1.05;
    }
  }

  .button-no-background:hover,
  .button-no-background:active,
  .button-no-background:focus {
    color: @layout-header-background;
    background: @layout-header-background;
  }

  .button-title:hover,
  .button-title:active {
    border-bottom: solid 0.15em white;
  }

  .beta-tag {
    left: -25px;
    position: relative;
    border-radius: 15px;
  }
}

.more-collections-menu {
  .ant-dropdown-menu {
    background-color: @layout-header-background !important;
    min-width: 160px;
  }

  .ant-dropdown-menu-item {
    line-height: 2.5rem;
    margin-top: 0.25rem;
    padding: 0 1rem;
    &:hover {
      background-color: @layout-header-background !important;
    }
  }

  .ant-dropdown-menu-item > a {
    display: unset;
    padding: 0;
  }

  .dropdown-link {
    font-size: 1rem;
    margin: 0;
    text-align: left;
    color: white;
    &:hover {
      background-color: @layout-header-background;
      border-bottom: solid 0.15em white;
      color: white;
    }
  }
}

@primary-color: #1890ff;@primary-with-opacity: #1890FF99;@link-color: #0050b3;@link-hover-color: #40a9ff;@layout-body-background: #f0f2f5;@success-color: #52c41a;@secondary-color: rgba(0, 0, 0, 0.65);@text-color: rgba(0, 0, 0, 0.8);@heading-color: rgba(0, 0, 0, 0.9);@screen-xs-max: 575px;@screen-sm-max: 767px;@screen-lg-max: 1159px;@screen-md-max: 991px;@header-padding: 0 12px;@drawer-body-padding: 24px;@btn-default-bg: #fff;@btn-height-base: 32px;@padding-md: 16px;@border-width-base: 1px;@border-style-base: solid;@border-color-split: rgb(232, 232, 232);@card-radius: 2px;@orange-6: #fa8c16;@orange-7: #d46b08;@blue-7: #096dd9;@gray-6: #bfbfbf;@page-content-padding: 0 2.85rem;@zindex-dropdown: 1050;@zindex-header: 1051;@zindex-footer: 1040;@zindex-modal: 1041;@zindex-drawer: 1041;@zindex-carousel: 1060;@layout-header-height: 64px;@layout-header-background: #001529;@modal-mask-bg: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);