.__RangeAggregation__ {
  .highlight-bar-on-hover {
    @hover-color: #69c0ff;
    rect:hover {
      fill: @hover-color !important;
      stroke: @hover-color !important;
    }
  }
  &.squeeze .ant-slider-mark-text {
    transform: translateX(-50%) translateY(50%) rotate(65deg) !important;
  }
  &.superSqueeze .ant-slider-mark-text:first-child {
    transform: translateX(-75%) translateY(50%) rotate(65deg) !important;
  }
  &.superSqueeze .ant-slider-mark-text:last-child {
    transform: translateX(-25%) translateY(50%) rotate(65deg) !important;
  }
}

@primary-color: #1890ff;@primary-with-opacity: #1890FF99;@link-color: #0050b3;@link-hover-color: #40a9ff;@layout-body-background: #f0f2f5;@success-color: #52c41a;@secondary-color: rgba(0, 0, 0, 0.65);@text-color: rgba(0, 0, 0, 0.8);@heading-color: rgba(0, 0, 0, 0.9);@screen-xs-max: 575px;@screen-sm-max: 767px;@screen-lg-max: 1159px;@screen-md-max: 991px;@header-padding: 0 12px;@drawer-body-padding: 24px;@btn-default-bg: #fff;@btn-height-base: 32px;@padding-md: 16px;@border-width-base: 1px;@border-style-base: solid;@border-color-split: rgb(232, 232, 232);@card-radius: 2px;@orange-6: #fa8c16;@orange-7: #d46b08;@blue-7: #096dd9;@gray-6: #bfbfbf;@page-content-padding: 0 2.85rem;@zindex-dropdown: 1050;@zindex-header: 1051;@zindex-footer: 1040;@zindex-modal: 1041;@zindex-drawer: 1041;@zindex-carousel: 1060;@layout-header-height: 64px;@layout-header-background: #001529;@modal-mask-bg: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);